var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c(
        "b-form",
        { attrs: { inline: "" } },
        [
          _c("b-button", { on: { click: _vm.getTemplate } }, [
            _vm._v("Update"),
          ]),
          _c("b-form-select", {
            attrs: { options: _vm.options },
            on: { change: _vm.changed },
            model: {
              value: _vm.option,
              callback: function ($$v) {
                _vm.option = $$v
              },
              expression: "option",
            },
          }),
        ],
        1
      ),
      _c(
        "b-tabs",
        { key: _vm.index, attrs: { "content-class": "mt-3" } },
        [
          _c("b-tab", { attrs: { title: "HTML", active: "" } }, [
            _c("div", {
              directives: [
                {
                  name: "dompurify-html",
                  rawName: "v-dompurify-html",
                  value: _vm.html,
                  expression: "html",
                },
              ],
            }),
          ]),
          _c(
            "b-tab",
            { attrs: { title: "TEXT" } },
            [
              _c("b-form-textarea", {
                attrs: { rows: "20" },
                model: {
                  value: _vm.text,
                  callback: function ($$v) {
                    _vm.text = $$v
                  },
                  expression: "text",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }