<template>
  <section>
    <b-form inline>
      <b-button @click="getTemplate">Update</b-button>

      <b-form-select
        @change="changed"
        v-model="option"
        :options="options"
      ></b-form-select>
    </b-form>

    <b-tabs content-class="mt-3" :key="index">
      <b-tab title="HTML" active><div v-dompurify-html="html"></div></b-tab>
      <b-tab title="TEXT">
        <b-form-textarea v-model="text" rows="20"></b-form-textarea
      ></b-tab>
    </b-tabs>
  </section>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      index: 0,
      options: [
        { text: "Invite", value: "invite" },
        {
          text: "FunctionBoxVerification",
          value: "functionbox-verification",
        },
        {
          text: "InviteOrganisation",
          value: "invite-organisation",
        },
        { text: "Link", value: "link" },
        { text: "Notification", value: "notification" },
        { text: "LinkResponse", value: "link-response" },
        { text: "Message", value: "message" },
        { text: "MessageResponse", value: "message-response" },
        {
          text: "InformationNotification",
          value: "information-notification",
        },
      ],
      loading: false,
      option: "invite",
      html: "",
      text: "",
    };
  },
  methods: {
    changed: function() {
      this.getTemplate();
    },
    getTemplate: function() {
      this.loading = true;
      let self = this;
      this.$http
        .post(this.user.hostname + "/email-template", {
          template: this.option
        })
        .then(function(response) {
          self.html = response.data.html;
          self.text = response.data.text;
          self.index++;
        })
        .catch((error) => {
          self.loading = false;
          if (error.response.data != undefined) {
            self.error = true;
            self.errorMessage = error.response.data;
          }
        });
    },
  },
  computed: {
  },
  mounted: function() {
    this.getTemplate();
  },
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
 {
  opacity: 0;
}
</style>
